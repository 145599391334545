/*----------------------
      GLOBAL APP SCSS
------------------------*/

//----------
//vars
$amarillo: #FFDB00;
$negro: #1D1D1B;
//----------

@import "~bootstrap/scss/bootstrap.scss";
@import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

@import "font";
@import "components";
@import "main";
@import "mediaqs";