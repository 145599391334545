/*----------------------
         MEDIAQs 
------------------------*/

@media (max-width: 1680px) {

   .floatings {

      .bodegon {
         right: -4vw;
      }
   }

}

@media (max-width: 1450px) {

   .floatings {

      .bodegon {
         right: -6vw;
         width: 580px;
      }
   }

}

@media (max-width: 1380px) {

   .home-page {
      overflow: hidden;
   }

   .floatings {

      .bodegon {
         top: 30vh;
         right: -14vw;
      }
   }

}

@media (max-width: 1200px) {
   .floatings {

      .bodegon {
         top: 40vh;
         width: 480px;
      }
   }

}



@media (max-width: 1050px) {
   .floatings {
      .bodegon {
        display: none;
      }
   }
}

@media (max-width: 730px) {
   .home-page {
      .info {
        .content-1 {
         .premio-info {
            div {
               line-height: 1.2;
               font-size: 2rem;
               &:first-child {
                  font-size: 2.5rem;
               }
            }
         }
        }
      }
   }
}



@media (max-width: 630px) {

   .home-page {
      .info {
         .content-2 {
            .pasos {
               .col-sm-4 {
                  margin-bottom: 2rem;
               }
            }
         }
      }
   }

   .footer {
      .links {
         flex-direction: column;
         align-items: center;

         li {
            margin-bottom: .6rem;

            &::after {
               content: none !important;
            }
         }
      }
   }

   .filebutton {
      font-size: 16px;
      margin-bottom: 1rem;
   }

   .camera {
      width: max-content;
      margin: 0 auto;
   }


}

@media (max-width: 530px) {
   .home-page {
      .info {
        .content-1 {
         .premio-info {
            div {
               line-height: 1.2;
               font-size: 1.5rem;
               &:first-child {
                  font-size: 2rem;
               }
            }
         }
        }

        .cta {
           font-size: 20px;
        }
      }
   }
}