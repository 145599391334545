/*----------------------
         MAIN 
------------------------*/

/* ===== Scrollbar CSS ===== */
* {
   scrollbar-width: thin;
   scrollbar-color: rgba(255, 255, 255, 0.7) rgba(255, 255, 255, 0.7);
}

*::-webkit-scrollbar {
   width: 3px;
   height: 3px;
}

*::-webkit-scrollbar-track {
   background: #ffffff;
}

*::-webkit-scrollbar-thumb {
   background-color: #ebebeb;
   border-radius: 10px;
   border: 3px solid #ffffff;
}

//----------------------

html {
   scroll-behavior: smooth;
}

html,
body {
   -webkit-font-smoothing: antialiased;
   font-weight: normal;
   color: $negro;
   background-color: $amarillo;
   font-family: 'Gotham';
   font-weight: normal;
}

.container {
   max-width: 1100px;
}

strong,
b {
   font-weight: bold;
}

.f-medium {
   font-weight: 500;
}

.f-black {
   font-weight: 900;
}

.f-dash {
   font-family: 'Dashiell Fine Demi';
   font-weight: 600;
}

h1 {
   font-weight: bold;
   background-color: $negro;
   padding: 10px 20px;
   text-transform: uppercase;
   color: $amarillo;
   width: fit-content;
   margin: 0 auto;
   text-align: center;
}

//----------------------

.main-navigation {
   .black-header {
      padding: 3rem 1rem;
      background-color: $negro;
   }
}

//----------------------

.footer {
   font-size: 14px;
   background-color: $negro;
   padding-top: 3rem;
   padding-bottom: 1rem;

   .logo-footer {
      max-width: 600px;
      margin: 0 auto 2rem;
   }

   .links {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      justify-content: center;
      margin-bottom: 0;

      li:not(:last-child):after {
         content: "/";
         margin-left: 10px;
         margin-right: 10px;
         color: #fff;
      }

      a {
         text-decoration: none;
         color: #fff;

         &:hover {
            text-decoration: underline;
         }
      }
   }

   p {
      margin-top: 0rem;
      color: #fff;
   }

}

//----------------------

.main-layout {
   background: url('../css/images/estrella.png') no-repeat 10rem 5rem;
   background-size: cover;
}

//----------------------

.home-page {

   .info {

      .content-1 {
         margin-top: 4rem;

         .premio-info {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 2rem;

            div {
               font-family: 'Dashiell Fine Demi';
               text-align: center;
               font-weight: 600;
               line-height: 1.2;
               font-size: 3rem;

               &:first-child {
                  font-size: 3.5rem;
               }
            }
         }
      }

      .content-2 {
         .pasos {
            width: 85%;
            margin: 4rem auto 2rem;

            .step {
               text-align: center;

               .info {
                  margin-top: 1.8rem;
                  text-align: center;
                  padding-left: 10px;

                  &.slim {
                     width: 80%;
                     margin: 1.8rem auto 0;
                  }
               }
            }
         }
      }

      .cta {
         font-weight: bold;
         font-size: 26px;
      }

      .promo-terms {
         font-size: 18px;
      }
   }

   .participa {

      .participa-cont {

         .slide-form {
            height: auto;
            transition: height 6.5s ease-out;

            &.sended {
               height: 0;
               overflow: hidden;
            }

            .main-form {
               text-align: left;
               min-height: 600px;
               padding-top: 2rem;

               form {
                  max-width: 500px;
                  margin: 0 auto;
               }


               input[type="text"],
               input[type="email"],
               input[type="datetime-local"],
               select {
                  border: 1px solid #fff;
                  border-radius: 0;
                  outline: none;
                  box-shadow: none;
               }

               .invalid-feedback {
                  display: inline-flex;
                  padding: 3px 5px 1px;
               }

               .is-checkbox {
                  .invalid-feedback {
                     position: relative;
                     top: -10px;
                     left: 26px;
                  }
               }

               .btn-send {
                  border: 2px solid $negro;
                  cursor: pointer;
                  background-color: $negro;
                  transition: all 0.2s ease;
                  color: $amarillo;
                  font-weight: bold;
                  text-transform: uppercase;
                  border-radius: 40px;
                  font-size: 25px;
                  padding-left: 50px;
                  padding-right: 50px;

                  &:hover {
                     background-color: #fff;
                     border-color: $negro;
                     color: $negro;
                  }
               }
            }
         }

         .result {
            opacity: 0;
            height: 0;
            overflow: hidden;
            transition: all 1s ease-in;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 3rem auto;

            h2 {
               font-size: 1.5rem;
            }

            p {
               font-size: 15px;
            }

            &.visible {
               height: auto !important;
               min-height: 400px;
               opacity: 1 !important;
            }
         }

      }

   }
}

//----------------------

.bbll-page {
   .textos {
      margin-bottom: 3rem;

      a {
         color: $negro;
         text-decoration: underline;
      }

      ol {
         font-size: 1.5rem;
      }
   }
}